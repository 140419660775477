<template>
  <CommonsModalsModalBody
    height="auto"
    :svg="isComptalib() ? 'ComptalibDocumentFolderSvg' : 'UploadDocumentSvg'"
  >
    <template #title> Importer une facture {{ isSales ? "de vente" : "d'achat" }} </template>

    <template #help>
      Importez vos factures {{ isSales ? "de vente" : `d'achat` }} (maximum 50 documents de 20 Mo
      chacun).
    </template>

    <template #content>
      <div>
        <div v-if="downloadWithoutAnnotationProcessing" class="stepper-percent">
          <div class="stepper-info">
            <span>Import des factures</span>
            <span class="percent" :class="{ light: percentage === 0 }">{{ percentage }}%</span>
          </div>

          <div class="stepper-wrapper">
            <div
              class="stepper"
              :style="{ width: (totalFileSizeUploaded / totalFileSize) * 100 + '%' }"
            />
          </div>
        </div>
        <CommonsDropzoneContainer
          v-else
          :extensions="basicImageAndPdfMimeType"
          :max-files="50"
          :max-size="20"
          :disabled="isLoadingWithAnnotation || isLoadingWithoutAnnotation"
          :error="errors.files"
          :show-drop-box-button="true"
          style="margin-bottom: 8px"
          @updated="form.files = $event"
        />
        <div v-if="!isAccruals">
          <uds-input-calendar
            label="Date d'émission"
            is-required
            :date="form.emissionDate"
            @change-date="form.emissionDate = $event"
          />
        </div>
        <div v-if="isAccruals && shouldDisplaySelectDocumentType">
          <uds-input-select-auto-popper
            label="Type de document"
            :value="form.documentType"
            :items="documentTypes"
            @select="form.documentType = $event || null"
          />
        </div>

        <div :class="{ 'cash-based': !isAccruals }">
          <div
            v-if="addedFileIsPdfType"
            class="uds-checkbox-line"
            @click.prevent="form.split = !form.split"
          >
            <uds-input-checkbox :is-checked="form.split" class="filter-checkbox" />
            <span class="filter-name">Séparer votre document en plusieurs pages</span>
          </div>
        </div>
        <div v-if="isAccruals">
          <div class="uds-checkbox-line" @click.prevent="form.bypassOcr = !form.bypassOcr">
            <uds-input-checkbox :is-checked="form.bypassOcr" class="filter-checkbox" />
            <span class="filter-name">Désactiver la reconnaissance visuelle</span>
            <uds-tooltip
              tooltip-text="L'importation sera plus rapide mais notre outil de reconnaissance visuelle ne pré-annotera pas vos documents."
            >
              <uds-icon
                class="icon-info"
                icon-name="info"
                size="small"
                :color="udsColors.udsNeutral500"
              />
            </uds-tooltip>
          </div>
        </div>
      </div>
      <div v-if="isSales" class="primary-highlight">
        Les factures importées ne sont pas comprises dans le calcul de votre solde.
      </div>
    </template>
    <template #footer>
      <div class="footer-actions">
        <uds-main-button
          type="tertiary"
          :disabled="isLoadingWithAnnotation || isLoadingWithoutAnnotation"
          size="small"
          style="margin-right: 16px"
          @click="$emit('cancel')"
        >
          Annuler
        </uds-main-button>
        <uds-main-button
          v-if="isPourcentage()"
          size="small"
          style="margin-right: 16px"
          :disabled="isSaveDisabled || isLoadingWithoutAnnotation"
          :loading="isLoadingWithAnnotation"
          type="secondary"
          @click="
            !isSaveDisabled && !isLoadingWithAnnotation && isAccruals
              ? importInvoices(true)
              : saveSaleInvoice()
          "
          >{{ isAccruals ? "Annoter par moi-même" : "Enregistrer" }}
        </uds-main-button>
        <template v-if="isAccruals">
          <uds-main-button
            size="small"
            style="margin-right: 16px"
            :disabled="isSaveDisabled || isLoadingWithAnnotation"
            :loading="isLoadingWithoutAnnotation"
            type="secondary"
            @click="!isSaveDisabled && !isLoadingWithoutAnnotation && importInvoices(false)"
          >
            Annoter plus tard
          </uds-main-button>
        </template>
        <uds-main-button
          v-if="!isPourcentage()"
          size="small"
          :loading="isLoadingWithAnnotation"
          :disabled="isSaveDisabled || isLoadingWithoutAnnotation"
          @click="
            !isSaveDisabled && !isLoadingWithAnnotation && isAccruals
              ? importInvoices(true)
              : saveSaleInvoice()
          "
        >
          {{ isAccruals ? "Annoter" : "Enregistrer" }}
        </uds-main-button>
      </div>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { ReadAccrualInvoice } from "@silexpert/core";
import { AccountingFileType } from "@silexpert/core";
import { useWebWorker } from "@vueuse/core";
import cloneDeep from "lodash-es/cloneDeep";

enum DOCUMENT_TYPE_ID {
  INVOICE = 1,
  TICKET = 2,
}

type AddSaleInvoiceFormData = {
  files: File[];
  split: boolean;
  emissionDate: string;
  documentType: DOCUMENT_TYPE_ID;
  bypassOcr: boolean;
};

const societyStore = useSocietyStore();
const annotateStore = useAnnotateStore();
const dayjs = useDayjs();
const { isPourcentage, isComptalib } = useBrandsComposable();
const udsColors = getUdsColors();

const props = defineProps<{
  droppedFiles?: File[];
  isSales: boolean;
}>();

const emit = defineEmits(["cancel", "close"]);

const form = ref<AddSaleInvoiceFormData>({
  files: [],
  split: false,
  emissionDate: dayjs().format("YYYY-MM-DD"),
  documentType: DOCUMENT_TYPE_ID.INVOICE,
  bypassOcr: false,
});

const externalConnectorFiles = ref<File[]>([]);

const documentTypes = [
  { text: "Facture", value: DOCUMENT_TYPE_ID.INVOICE },
  { text: "Ticket de caisse", value: DOCUMENT_TYPE_ID.TICKET },
];

const isLoadingWithoutAnnotation = ref(false);
const isLoadingWithAnnotation = ref(false);
const rejected = ref(false); // true

const totalFileSize = ref(0);
const totalFile = ref(0);
const totalFileSizeRef = ref(0);
const totalFileSizeUploaded = ref(0);
const previousFileSizeUploaded = ref(0);
const downloadWithoutAnnotationProcessing = ref(false);
const totalFileUploadedInError = ref<{ name: string }[]>([]);

const percentage = computed(() => {
  const calcul = Math.round((totalFileSizeUploaded.value / totalFileSize.value) * 100);
  return isNaN(calcul) ? 0 : calcul;
});

const isAccruals = computed<boolean>(() => societyStore.isAccruals);
const isInputUploadValid = computed(() => form.value.files.length >= 1);
const addedFileIsPdfType = computed(() => {
  const mimeTypes: string[] = form.value.files.map((addedFile) => (addedFile as File).type);
  return mimeTypes.every((type) => type === "application/pdf");
});
const shouldDisplaySelectDocumentType = computed(
  () => isArray(form.value.files) && form.value.files.length < 2,
);

const errors = computed<{ [key: string]: string | null }>(() => {
  const { files } = form.value;
  return {
    files: files.length === 0 ? "Veuillez importer un fichier" : null,
  };
});

const isValid = computed<boolean>(() => {
  for (const property in errors.value) {
    const value = errors.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

const isSaveDisabled = computed(
  () => !isValid.value || !isInputUploadValid.value || rejected.value,
);

watch(
  () => props.droppedFiles,
  (value) => {
    if (value) {
      handleSelectedFiles(value);
    }
  },
  { immediate: true },
);

async function saveSaleInvoice() {
  try {
    isLoadingWithAnnotation.value = true;
    await $silex().accountingFiles.accountingFile({
      idAccountingType: props.isSales
        ? AccountingFileType.SALE_INVOICES
        : AccountingFileType.PURCHASE_INVOICES,
      split: addedFileIsPdfType.value ? form.value.split : false,
      isPayPerso: false,
      files: [...form.value.files],
      bypassOcr: form.value.bypassOcr,
      emissionDate: form.value.emissionDate!,
    });
    $notifier().open({ type: "success", content: "Facture(s) ajoutée(s) avec succès" });
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  }
  emit("close");
}

async function importInvoices(shouldAnnotateImmediately: boolean) {
  const idSociety = societyStore.society!.id!;
  if (shouldAnnotateImmediately) {
    isLoadingWithAnnotation.value = true;
    if (props.isSales) {
      if (form.value.files.length === 1) {
        const result = await $silex().accountingFiles.accountingFile({
          idAccountingType: AccountingFileType.SALE_INVOICES,
          split: addedFileIsPdfType.value ? form.value.split : false,
          isPayPerso: false,
          files: form.value.files,
          bypassOcr: form.value.bypassOcr,
        });
        const clonedList = cloneDeep(toRaw(annotateStore.importedTransactions ?? []));
        const newList = [...clonedList, result[0]];
        annotateStore.importedTransactions = newList;
        annotateStore.$patch({
          status: "finished",
        });
      } else {
        const { token } = useAuth();
        form.value.files.forEach((file) => {
          const { worker } = useWebWorker("../../worker.js");
          if (worker.value!) {
            worker.value!.postMessage({
              // @ts-expect-error
              url: `${apiHost}society/accountingFiles`,
              method: "POST",
              headers: {
                // "Content-type": "multipart/form-data",
                Authorization: `${token.value}`,
              },
              body: {
                idAccountingType: AccountingFileType.SALE_INVOICES,
                split: addedFileIsPdfType.value ? form.value.split : false,
                isPayPerso: false,
                files: [file],
                bypassOcr: form.value.bypassOcr,
                withSocketReturn: true,
              },
              isFormData: true,
            });
          }
        });

        annotateStore.$patch({
          status: "pending",
          totalFileToUpload: form.value.files.length,
        });
      }
    } else if (form.value.files.length === 1) {
      await $silex()
        .accountingFiles.createAccrualAccountingFile({
          idAccountingType: AccountingFileType.PURCHASE_INVOICES,
          split: addedFileIsPdfType.value ? form.value.split : false,
          isPayPerso: false,
          files: form.value.files,
          bypassOcr: form.value.bypassOcr,
          idSociety,
        })
        .then((result: ReadAccrualInvoice[]) => {
          const clonedList = cloneDeep(toRaw(annotateStore.importedTransactions ?? []));
          const newList = [...clonedList, result[0]];
          annotateStore.importedTransactions = newList;
          annotateStore.$patch({
            status: "finished",
          });
        });
    } else {
      const { token } = useAuth();
      form.value.files.forEach((file) => {
        const { worker } = useWebWorker("../../worker.js");
        if (worker.value!) {
          worker.value!.postMessage({
            // @ts-expect-error
            url: `${apiHost}societies/${idSociety}/accounting-files`,
            method: "POST",
            headers: {
              // "Content-type": "multipart/form-data",
              Authorization: `${token.value}`,
            },
            body: {
              idAccountingType: AccountingFileType.PURCHASE_INVOICES,
              split: addedFileIsPdfType.value ? form.value.split : false,
              isPayPerso: false,
              files: [file],
              bypassOcr: form.value.bypassOcr,
              idSociety,
              withSocketReturn: true,
            },
            isFormData: true,
          });
        }
      });
      annotateStore.$patch({
        status: "pending",
        totalFileToUpload: form.value.files.length,
      });
    }
  } else {
    totalFile.value = form.value.files.length;
    isLoadingWithoutAnnotation.value = true;
    downloadWithoutAnnotationProcessing.value = true;
    form.value.files.forEach((file) => {
      totalFileSize.value += file.size;
    });
    const timeout = setInterval(autoLoading, 10);
    if (props.isSales) {
      await Promise.allSettled(
        form.value.files.map(async (file) => {
          await $silex()
            .accountingFiles.accountingFile({
              idAccountingType: AccountingFileType.SALE_INVOICES,
              split: addedFileIsPdfType.value ? form.value.split : false,
              isPayPerso: false,
              files: [file],
              bypassOcr: form.value.bypassOcr,
            })
            .then(() => {
              totalFileSizeRef.value += file.size;
              totalFileSizeUploaded.value = totalFileSizeRef.value;
              previousFileSizeUploaded.value = totalFileSizeUploaded.value;
            })
            .catch(() => {
              totalFileSizeRef.value += file.size;
              totalFileSizeUploaded.value = totalFileSizeRef.value;
              previousFileSizeUploaded.value = totalFileSizeUploaded.value;
              totalFileUploadedInError.value.push({ name: file.name });
              annotateStore.$patch({
                totalFilesInError: totalFileUploadedInError.value,
              });
            });
        }),
      );
    } else {
      await Promise.allSettled(
        form.value.files.map(async (file) => {
          await $silex()
            .accountingFiles.createAccrualAccountingFile({
              idAccountingType: AccountingFileType.PURCHASE_INVOICES,
              split: addedFileIsPdfType.value ? form.value.split : false,
              isPayPerso: false,
              files: [file],
              bypassOcr: form.value.bypassOcr,
              idSociety,
            })
            .then(() => {
              totalFileSizeRef.value += file.size;
              totalFileSizeUploaded.value = totalFileSizeRef.value;
              previousFileSizeUploaded.value = totalFileSizeUploaded.value;
            })
            .catch(() => {
              totalFileSizeRef.value += file.size;
              totalFileSizeUploaded.value = totalFileSizeRef.value;
              previousFileSizeUploaded.value = totalFileSizeUploaded.value;
              totalFileUploadedInError.value.push({ name: file.name });
              annotateStore.$patch({
                totalFilesInError: totalFileUploadedInError.value,
              });
            });
        }),
      );
    }
    clearInterval(timeout);
    annotateStore.$patch({
      totalFileToUpload: form.value.files.length,
    });

    if (totalFileUploadedInError.value.length > 0) {
      annotateStore.$patch({
        forceDisplayErrorModale: true,
      });
    }
  }
  annotateStore.isExpenseNote = false;
  isLoadingWithoutAnnotation.value = false;
  emit("close");
}

function autoLoading() {
  const value = totalFileSizeUploaded.value;
  const previousValue = previousFileSizeUploaded.value;
  if (value === previousValue) {
    totalFileSizeUploaded.value = totalFileSizeUploaded.value + totalFileSize.value / 10000;
    previousFileSizeUploaded.value = totalFileSizeUploaded.value;
  }
}

function handleSelectedFiles(files: File[]) {
  externalConnectorFiles.value = files;
}
</script>

<style lang="scss" scoped>
.primary-highlight {
  background: $uds-primary-50;
  border-radius: 5px;
  padding: $uds-spacing-1 15px 10px 15px;
  font-size: 14px;
  line-height: 18px;
  color: $uds-primary-500;
  margin-top: $uds-spacing-2;
}

.footer-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-info {
  margin-left: $uds-spacing-1;
  cursor: pointer;
}

.stepper-percent {
  display: flex;
  height: 156px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px dashed $uds-neutral-400;
  background: $uds-neutral-200;
  margin-top: $uds-spacing-3;
  margin-bottom: $uds-spacing-3;
  .stepper-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: $uds-spacing-1;
    width: 100%;
    font-size: 16px;
    color: $uds-neutral-900;
    font-weight: $uds-weight-450;
    .percent {
      color: $uds-primary-500;
      font-weight: $uds-weight-700;
    }

    .light {
      color: $uds-neutral-700;
    }
  }

  .stepper-wrapper {
    border: 1px solid $uds-neutral-300;
    height: 8px;
    background-color: $uds-neutral-300;
    width: 100%;
    border-radius: $uds-spacing-4;

    .stepper {
      background-color: $uds-primary-500;
      border-radius: $uds-spacing-4;
      height: 100%;
    }
  }

  .stepper-error {
    color: $uds-error-500;
    font-size: 16px;
    font-weight: $uds-weight-450;
    align-self: flex-start;
  }
}
</style>
